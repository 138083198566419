import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';

export const StorePickUpMap = () => {
  const { sm } = useBreakpointsState();
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={sm ? '1px solid' : ''}>
          <Typography variant="h6" color="textPrimary">
            Acercate a nuestra sucursal en: Caboto 550, La Boca, C.P 1157, de lunes a viernes de 9 a 16hs.
          </Typography>
        </Box>
        <iframe
          // eslint-disable-next-line max-len
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2321.452725405231!2d-58.36082331384488!3d-34.628683963926115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a334c7c8e46495%3A0x6535ca09bc1b8f9e!2sCaboto%20550%2C%20C1157ABJ%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1732541321536!5m2!1ses!2sar"
          title="store_pickup"
          width="80%"
          height="300vh"
          loading="lazy"
          style={{ borderRadius: '1em', border: '1px solid #000', marginLeft: '10%' }}
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Grid>
    </Grid>
  );
};