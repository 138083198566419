import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { IDeliveryAddressInfo } from '../../initialValues';

interface DeliveryInfoViewProps {
  data: IDeliveryAddressInfo;
  isStorePickup: boolean;
}

export const DeliveryInfoView = ({ data, isStorePickup }: DeliveryInfoViewProps) => (
  <Grid container>
    {(!!data.province.name || isStorePickup) && (
      <Box width="auto" display="inline-grid">
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {isStorePickup ? `Caboto 550` : `${data.street} ${data.streetNumber} ${data.floor} ${data.apartament}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {isStorePickup ? `CABA` : `${data.province?.name}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {isStorePickup ? `La Boca, CP 1157` : `${data.city?.name}, CP ${data.zipCode}`}
          </Typography>
        </Grid>
        <Grid item container xs={12} zeroMinWidth>
          <Typography variant="caption" color="textPrimary">
            {isStorePickup ? `` : `${data.additionalInfo}`}
          </Typography>
        </Grid>
      </Box>
    )}
  </Grid>
);
